import * as React from "react";
import { FaTools } from "react-icons/fa";
import "./App.Styles.scss";

const App: React.FC = () => {
	return (
		<div className="wrapper bg-dark">
			<div className="container content">
				<h1 className="title">Under Construction</h1>
				<div className="display-1 mb-3 text-warning">
					<FaTools />
				</div>
				<p className="lead text-center">Something cool is coming soon.</p>
			</div>
		</div>
	);
};

export default App;
